:root, :host {
  --ol-background-color: white;
  --ol-accent-background-color: #f5f5f5;
  --ol-subtle-background-color: #80808040;
  --ol-partial-background-color: #ffffffbf;
  --ol-foreground-color: #333;
  --ol-subtle-foreground-color: #666;
  --ol-brand-color: #0af;
}

.ol-box {
  box-sizing: border-box;
  border: 1.5px solid var(--ol-background-color);
  background-color: var(--ol-partial-background-color);
  border-radius: 2px;
}

.ol-mouse-position {
  position: absolute;
  top: 8px;
  right: 8px;
}

.ol-scale-line {
  background: var(--ol-partial-background-color);
  border-radius: 4px;
  padding: 2px;
  position: absolute;
  bottom: 8px;
  left: 8px;
}

.ol-scale-line-inner {
  border: 1px solid var(--ol-subtle-foreground-color);
  color: var(--ol-foreground-color);
  text-align: center;
  will-change: contents, width;
  border-top: none;
  margin: 1px;
  font-size: 10px;
  transition: all .25s;
}

.ol-scale-bar {
  position: absolute;
  bottom: 8px;
  left: 8px;
}

.ol-scale-bar-inner {
  display: flex;
}

.ol-scale-step-marker {
  background-color: var(--ol-foreground-color);
  float: right;
  z-index: 10;
  width: 1px;
  height: 15px;
}

.ol-scale-step-text {
  z-index: 11;
  color: var(--ol-foreground-color);
  text-shadow: -1.5px 0 var(--ol-partial-background-color), 0 1.5px var(--ol-partial-background-color), 1.5px 0 var(--ol-partial-background-color), 0 -1.5px var(--ol-partial-background-color);
  font-size: 10px;
  position: absolute;
  bottom: -5px;
}

.ol-scale-text {
  text-align: center;
  color: var(--ol-foreground-color);
  text-shadow: -1.5px 0 var(--ol-partial-background-color), 0 1.5px var(--ol-partial-background-color), 1.5px 0 var(--ol-partial-background-color), 0 -1.5px var(--ol-partial-background-color);
  font-size: 12px;
  position: absolute;
  bottom: 25px;
}

.ol-scale-singlebar {
  z-index: 9;
  box-sizing: border-box;
  border: 1px solid var(--ol-foreground-color);
  height: 10px;
  position: relative;
}

.ol-scale-singlebar-even {
  background-color: var(--ol-subtle-foreground-color);
}

.ol-scale-singlebar-odd {
  background-color: var(--ol-background-color);
}

.ol-unsupported {
  display: none;
}

.ol-viewport, .ol-unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.ol-viewport canvas {
  all: unset;
  overflow: hidden;
}

.ol-viewport {
  touch-action: pan-x pan-y;
}

.ol-selectable {
  -webkit-touch-callout: default;
  -webkit-user-select: text;
  user-select: text;
}

.ol-grabbing {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.ol-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.ol-control {
  background-color: var(--ol-subtle-background-color);
  border-radius: 4px;
  position: absolute;
}

.ol-zoom {
  top: .5em;
  left: .5em;
}

.ol-rotate {
  transition: opacity .25s linear, visibility linear;
  top: .5em;
  right: .5em;
}

.ol-rotate.ol-hidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity .25s linear, visibility 0s linear .25s;
}

.ol-zoom-extent {
  top: 4.643em;
  left: .5em;
}

.ol-full-screen {
  top: .5em;
  right: .5em;
}

.ol-control button {
  color: var(--ol-subtle-foreground-color);
  font-weight: bold;
  font-size: inherit;
  text-align: center;
  background-color: var(--ol-background-color);
  border: none;
  border-radius: 2px;
  width: 1.375em;
  height: 1.375em;
  margin: 1px;
  padding: 0;
  line-height: .4em;
  text-decoration: none;
  display: block;
}

.ol-control button::-moz-focus-inner {
  border: none;
  padding: 0;
}

.ol-zoom-extent button {
  line-height: 1.4em;
}

.ol-compass {
  will-change: transform;
  font-weight: normal;
  display: block;
}

.ol-touch .ol-control button {
  font-size: 1.5em;
}

.ol-touch .ol-zoom-extent {
  top: 5.5em;
}

.ol-control button:hover, .ol-control button:focus {
  outline: 1px solid var(--ol-subtle-foreground-color);
  color: var(--ol-foreground-color);
  text-decoration: none;
}

.ol-zoom .ol-zoom-in {
  border-radius: 2px 2px 0 0;
}

.ol-zoom .ol-zoom-out {
  border-radius: 0 0 2px 2px;
}

.ol-attribution {
  text-align: right;
  flex-flow: row-reverse;
  align-items: center;
  max-width: calc(100% - 1.3em);
  display: flex;
  bottom: .5em;
  right: .5em;
}

.ol-attribution a {
  color: var(--ol-subtle-foreground-color);
  text-decoration: none;
}

.ol-attribution ul {
  color: var(--ol-foreground-color);
  text-shadow: 0 0 2px var(--ol-background-color);
  margin: 0;
  padding: 1px .5em;
  font-size: 12px;
}

.ol-attribution li {
  list-style: none;
  display: inline;
}

.ol-attribution li:not(:last-child):after {
  content: " ";
}

.ol-attribution img {
  max-width: inherit;
  vertical-align: middle;
  max-height: 2em;
}

.ol-attribution button {
  flex-shrink: 0;
}

.ol-attribution.ol-collapsed ul {
  display: none;
}

.ol-attribution:not(.ol-collapsed) {
  background: var(--ol-partial-background-color);
}

.ol-attribution.ol-uncollapsible {
  border-radius: 4px 0 0;
  bottom: 0;
  right: 0;
}

.ol-attribution.ol-uncollapsible img {
  max-height: 1.6em;
  margin-top: -.2em;
}

.ol-attribution.ol-uncollapsible button {
  display: none;
}

.ol-zoomslider {
  height: 200px;
  top: 4.5em;
  left: .5em;
}

.ol-zoomslider button {
  height: 10px;
  position: relative;
}

.ol-touch .ol-zoomslider {
  top: 5.5em;
}

.ol-overviewmap {
  bottom: .5em;
  left: .5em;
}

.ol-overviewmap.ol-uncollapsible {
  border-radius: 0 4px 0 0;
  bottom: 0;
  left: 0;
}

.ol-overviewmap .ol-overviewmap-map, .ol-overviewmap button {
  display: block;
}

.ol-overviewmap .ol-overviewmap-map {
  border: 1px solid var(--ol-subtle-foreground-color);
  width: 150px;
  height: 150px;
}

.ol-overviewmap:not(.ol-collapsed) button {
  position: absolute;
  bottom: 0;
  left: 0;
}

.ol-overviewmap.ol-collapsed .ol-overviewmap-map, .ol-overviewmap.ol-uncollapsible button {
  display: none;
}

.ol-overviewmap:not(.ol-collapsed) {
  background: var(--ol-subtle-background-color);
}

.ol-overviewmap-box {
  border: 1.5px dotted var(--ol-subtle-foreground-color);
}

.ol-overviewmap .ol-overviewmap-box:hover {
  cursor: move;
}

.ol-overviewmap .ol-viewport:hover {
  cursor: pointer;
}

@keyframes wb-fade-in {
  0% {
    opacity: 0;
  }

  to {
    opacity: .85;
  }
}

.winbox {
  contain: layout size;
  text-align: left;
  touch-action: none;
  background: #0050ff;
  transition: width .3s cubic-bezier(.3, 1, .3, 1), height .3s cubic-bezier(.3, 1, .3, 1), left .3s cubic-bezier(.3, 1, .3, 1), top .3s cubic-bezier(.3, 1, .3, 1);
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 14px 28px #00000040, 0 10px 10px #00000038;
}

.wb-body, .wb-header {
  position: absolute;
  left: 0;
}

.wb-header {
  color: #fff;
  z-index: 1;
  width: 100%;
  height: 35px;
  line-height: 35px;
  top: 0;
  overflow: hidden;
}

.wb-body {
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  will-change: contents;
  contain: strict;
  z-index: 0;
  background: #fff;
  top: 35px;
  bottom: 0;
  right: 0;
  overflow: auto;
  margin-top: 0 !important;
}

.wb-control *, .wb-icon {
  background-repeat: no-repeat;
}

body > .wb-body {
  visibility: hidden;
  contain: none;
  display: inline-block;
  position: relative;
}

.wb-drag {
  cursor: move;
  height: 100%;
  padding-left: 10px;
}

.wb-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
}

.wb-icon {
  float: left;
  background-position: center;
  background-size: 100%;
  width: 20px;
  height: 100%;
  margin: -1px 8px 0 -3px;
  display: none;
}

.wb-e, .wb-w {
  width: 10px;
  top: 0;
}

.wb-n, .wb-s {
  height: 10px;
  position: absolute;
  left: 0;
}

.wb-n {
  cursor: n-resize;
  z-index: 2;
  top: -5px;
  right: 0;
}

.wb-e {
  cursor: w-resize;
  z-index: 2;
  position: absolute;
  bottom: 0;
  right: -5px;
}

.wb-s {
  cursor: n-resize;
  z-index: 2;
  bottom: -5px;
  right: 0;
}

.wb-nw, .wb-sw, .wb-w {
  left: -5px;
}

.wb-w {
  cursor: w-resize;
  z-index: 2;
  position: absolute;
  bottom: 0;
}

.wb-ne, .wb-nw, .wb-sw {
  z-index: 2;
  width: 15px;
  height: 15px;
  position: absolute;
}

.wb-nw {
  cursor: nw-resize;
  top: -5px;
}

.wb-ne, .wb-sw {
  cursor: ne-resize;
}

.wb-ne {
  top: -5px;
  right: -5px;
}

.wb-se, .wb-sw {
  bottom: -5px;
}

.wb-se {
  cursor: nw-resize;
  z-index: 2;
  width: 15px;
  height: 15px;
  position: absolute;
  right: -5px;
}

.wb-control {
  float: right;
  text-align: center;
  max-width: 100%;
  height: 100%;
}

.wb-control * {
  cursor: pointer;
  background-position: center;
  width: 30px;
  max-width: 100%;
  height: 100%;
  display: inline-block;
}

.no-close .wb-close, .no-full .wb-full, .no-header .wb-header, .no-max .wb-max, .no-min .wb-min, .no-resize .wb-body ~ div, .wb-body .wb-hide, .wb-show, .winbox.hide, .winbox.min .wb-body > *, .winbox.min .wb-full, .winbox.min .wb-min, .winbox.modal .wb-full, .winbox.modal .wb-max, .winbox.modal .wb-min {
  display: none;
}

.winbox.max .wb-drag, .winbox.min .wb-drag {
  cursor: default;
}

.wb-min {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiAyIj48cGF0aCBmaWxsPSIjZmZmIiBkPSJNOCAwaDdhMSAxIDAgMCAxIDAgMkgxYTEgMSAwIDAgMSAwLTJoN3oiLz48L3N2Zz4=");
  background-position: 50% calc(50% + 6px);
  background-size: 14px;
}

.wb-max {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiNmZmYiIHZpZXdCb3g9IjAgMCA5NiA5NiI+PHBhdGggZD0iTTIwIDcxLjMxMUMxNS4zNCA2OS42NyAxMiA2NS4yMyAxMiA2MFYyMGMwLTYuNjMgNS4zNy0xMiAxMi0xMmg0MGM1LjIzIDAgOS42NyAzLjM0IDExLjMxMSA4SDI0Yy0yLjIxIDAtNCAxLjc5LTQgNHY1MS4zMTF6Ii8+PHBhdGggZD0iTTkyIDc2VjM2YzAtNi42My01LjM3LTEyLTEyLTEySDQwYy02LjYzIDAtMTIgNS4zNy0xMiAxMnY0MGMwIDYuNjMgNS4zNyAxMiAxMiAxMmg0MGM2LjYzIDAgMTItNS4zNyAxMi0xMnptLTUyIDRjLTIuMjEgMC00LTEuNzktNC00VjM2YzAtMi4yMSAxLjc5LTQgNC00aDQwYzIuMjEgMCA0IDEuNzkgNCA0djQwYzAgMi4yMS0xLjc5IDQtNCA0SDQweiIvPjwvc3ZnPg==");
  background-size: 17px;
}

.wb-close {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xIC0xIDE4IDE4Ij48cGF0aCBmaWxsPSIjZmZmIiBkPSJtMS42MTMuMjEuMDk0LjA4M0w4IDYuNTg1IDE0LjI5My4yOTNsLjA5NC0uMDgzYTEgMSAwIDAgMSAxLjQwMyAxLjQwM2wtLjA4My4wOTRMOS40MTUgOGw2LjI5MiA2LjI5M2ExIDEgMCAwIDEtMS4zMiAxLjQ5N2wtLjA5NC0uMDgzTDggOS40MTVsLTYuMjkzIDYuMjkyLS4wOTQuMDgzQTEgMSAwIDAgMSAuMjEgMTQuMzg3bC4wODMtLjA5NEw2LjU4NSA4IC4yOTMgMS43MDdBMSAxIDAgMCAxIDEuNjEzLjIxeiIvPjwvc3ZnPg==");
  background-position: 5px;
  background-size: 15px;
}

.wb-full {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2Utd2lkdGg9IjIuNSIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNOCAzSDVhMiAyIDAgMCAwLTIgMnYzbTE4IDBWNWEyIDIgMCAwIDAtMi0yaC0zbTAgMThoM2EyIDIgMCAwIDAgMi0ydi0zTTMgMTZ2M2EyIDIgMCAwIDAgMiAyaDMiLz48L3N2Zz4=");
  background-size: 16px;
}

.winbox.max .wb-body ~ div, .winbox.min .wb-body ~ div, .winbox.modal .wb-body ~ div, .winbox.modal .wb-drag, body.wb-lock iframe {
  pointer-events: none;
}

.winbox.max {
  box-shadow: none;
}

.winbox.max .wb-body {
  margin: 0 !important;
}

.winbox iframe {
  border: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

body.wb-lock .winbox {
  will-change: left, top, width, height;
  transition: none;
}

.winbox.modal:before {
  content: "";
  background: inherit;
  border-radius: inherit;
  position: absolute;
  inset: 0;
}

.winbox.modal:after {
  content: "";
  z-index: -1;
  background: #0d1117;
  animation: .2s ease-out forwards wb-fade-in;
  position: absolute;
  inset: -50vh -50vw;
}

.no-animation {
  transition: none;
}

.no-shadow {
  box-shadow: none;
}

.no-header .wb-body {
  top: 0;
}

.no-move:not(.min) .wb-title {
  pointer-events: none;
}

.wb-body .wb-show {
  display: revert;
}
/*# sourceMappingURL=index.43a85fb4.css.map */
